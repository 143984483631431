import { TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { useCreateFamilyMemberMutation } from "../../generated/graphql";

function AddRecipientForm({
  userId,
  afterSubmit,
}: {
  userId: string;
  afterSubmit: Function;
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formError, setFormError] = useState("");

  const [createFamilyMemberMutation, { loading, error }] =
    useCreateFamilyMemberMutation({
      variables: {
        familyMember: {
          firstName,
          lastName,
        },
        userId: userId,
      },
    });

  const OnSubmit = async () => {
    if (
      firstName &&
      firstName.trim() !== "" &&
      lastName &&
      lastName.trim() !== ""
    ) {
      const result = await createFamilyMemberMutation();
      afterSubmit(result.data?.createFamilyMember);
    } else {
      setFormError("First and last names are required");
    }
  };

  const Cancel = () => {
    afterSubmit();
  };

  return (
    <div className="spaced-section">
      <TextField
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        size="small"
        label="First Name"
      />
      <TextField
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        size="small"
        label="Last Name"
      />
      {error && <div>Error: {error.message}</div>}
      {formError && <div>Error: {formError}</div>}
      <Button
        disabled={loading}
        variant="contained"
        size="small"
        onClick={OnSubmit}
      >
        Add
      </Button>
      <Button onClick={Cancel}>Cancel</Button>
    </div>
  );
}

export default AddRecipientForm;
