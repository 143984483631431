import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";

function AddressForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h5>Address</h5>
      <div>
        <TextField
          size="small"
          label="Street"
          error={!!errors.street}
          {...register("address.street")}
        />
        <TextField
          size="small"
          label="Street Additional"
          error={!!errors.streetAdditional}
          {...register("address.streetAdditional")}
        />
        <TextField size="small" label="City" {...register("address.city")} />
        <TextField size="small" label="State" {...register("address.state")} />
        <TextField
          size="small"
          label="Zip Code"
          {...register("address.zipCode")}
        />
      </div>
    </div>
  );
}

export default AddressForm;
