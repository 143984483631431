import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
} from "react-router-dom";
import ApolloProviderWrapper from "./wrapper/ApolloProviderWrapper";
import BillSubmissions from "./pages/BillSubmissions";
import UserSpecificBillSubmissions from "./pages/UserSpecificBillSubmissions";
import Users from "./pages/Users";
import User from "./pages/User";
import UpdateBill from "./pages/UpdateBill";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import Home from "./pages/Home";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const APP_STAGE = process.env.REACT_APP_STAGE ?? "dev";
Amplify.configure(awsExports(APP_STAGE));

const router = createBrowserRouter([
  {
    path: "*",
    element: <Root />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function Root() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <>
          <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="/">Oopda Health Admin Panel</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/bill-submissions">Bill Submissions</Nav.Link>
                  <Nav.Link href="/users">Users</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Routes>
            <Route path="/" element={<Home user={user} signOut={signOut} />} />
            <Route path="/bill-submissions" element={<BillSubmissions />} />
            <Route
              path="/bill-submissions/:userId"
              element={<UserSpecificBillSubmissions />}
            />
            <Route path="/users" element={<Users />}></Route>
            <Route path="/users/:userId" element={<User />}></Route>
            <Route
              path="/users/:userId/bills/:billId"
              element={<UpdateBill />}
            ></Route>
          </Routes>
        </>
      )}
    </Authenticator>
  );
}

root.render(
  <React.StrictMode>
    <ApolloProviderWrapper>
      <RouterProvider router={router} />
    </ApolloProviderWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
