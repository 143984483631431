import {
  Button,
  CircularProgress,
  TextField,
  Container,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetBillsLazyQuery, UserFragment } from "../../generated/graphql";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BillIdentificationInput = ({
  user,
  isBillUpdate,
  setUpdateBill,
}: {
  user: UserFragment;
  isBillUpdate: boolean;
  setUpdateBill?: (billId: string) => void;
}) => {
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState<String | null>(null);
  const [searchVariables, setSearchVariables] = useState({});

  const { register, getValues } = useFormContext();

  const [getBills, { loading, error, data }] = useGetBillsLazyQuery({
    variables: { filterOptions: searchVariables, userId: user.id },
  });

  const searchBills = ({
    accountId,
    invoiceNumber,
  }: {
    accountId?: Boolean;
    invoiceNumber?: Boolean;
  }) => {
    setErrorMessage(null);
    setDisplay(true);
    if (accountId) {
      if (!getValues("accountId")) {
        setErrorMessage("Please provide an account ID");
        return;
      }
      setSearchVariables({ accountId: getValues("accountId") });
      getBills();
    } else if (invoiceNumber) {
      if (!getValues("invoiceNumber")) {
        setErrorMessage("Please provide an invoice number");
        return;
      }
      setSearchVariables({ invoiceNumber: getValues("invoiceNumber") });
      getBills();
    } else {
      setErrorMessage("Please provide an account ID or invoice number");
    }
  };

  return (
    <>
      <h5>Identification</h5>
      <div>
        <TextField size="small" label="Account ID" {...register("accountId")} />
        <TextField
          size="small"
          label="Invoice Number"
          {...register("invoiceNumber")}
        />
      </div>
      {!isBillUpdate && (
        <div>
          <Button
            variant="contained"
            onClick={() => searchBills({ accountId: true })}
          >
            Search bills with accountId
          </Button>
          <Button
            variant="contained"
            onClick={() => searchBills({ invoiceNumber: true })}
          >
            Search bills with invoiceNumber
          </Button>
        </div>
      )}
      {display && (
        <Container style={{ border: "2px solid gray", borderRadius: 4 }}>
          <h5>Bills</h5>
          {data?.getBills.bills && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Edit Bill</TableCell>
                    <TableCell>Bill ID</TableCell>
                    <TableCell>Account Id</TableCell>
                    <TableCell align="right">Invoice Number</TableCell>
                    <TableCell align="right">Service Date</TableCell>
                    <TableCell align="right">Statement Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.getBills.bills.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        {setUpdateBill && (
                          <Button onClick={() => setUpdateBill(row.id)}>
                            Edit Bill
                          </Button>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.accountId}
                      </TableCell>
                      <TableCell align="right">{row.invoiceNumber}</TableCell>
                      <TableCell align="right">{row.serviceDate}</TableCell>
                      <TableCell align="right">{row.statementDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div>{loading && <CircularProgress />}</div>

          {error && <Alert severity="error">{error.message}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Button
            onClick={() => {
              setErrorMessage(null);
              setDisplay(false);
            }}
          >
            Clear
          </Button>
        </Container>
      )}
    </>
  );
};

export default BillIdentificationInput;
