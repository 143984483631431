import { useGetBillImagesQuery } from "../generated/graphql";
import React, { useEffect, useState } from "react";
import UserSpecificBillImagesListItem from "../components/UserSpecificBillImagesListItem";
import { Link } from "react-router-dom";

function BillSubmissions() {
  const [userIds, setUserIds] = useState<Set<string>>(new Set());

  const { data } = useGetBillImagesQuery({
    variables: {
      filterOptions: {
        isProcessed: false,
      },
      paginationOptions: {
        limit: 10,
      },
    },
  });

  useEffect(() => {
    if (data && data.getBillImages?.billImages) {
      // @ts-ignore
      setUserIds(
        new Set(
          data.getBillImages?.billImages.map((billImage) => billImage?.userId)
        )
      );
    }
  }, [data]);

  return (
    <div>
      <h1>BillSubmissions</h1>
      <div>
        {Array.from(userIds).map((userId) => (
          <Link to={userId} key={userId}>
            <UserSpecificBillImagesListItem userId={userId} key={userId} />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BillSubmissions;
