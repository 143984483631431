import BillForm from "./BillForm";
import moment from "moment";
import {
  useCreateBillMutation,
  UserFragment,
  useUpdateBillImagesMutation,
} from "../../generated/graphql";
import React, { useEffect, useState } from "react";
import "./CreateBillForm.css";

const DEFAULT_BILL = (dateString: string) => {
  return {
    accountId: "",
    invoiceNumber: "",
    amountDue: 0,
    serviceDate: dateString,
    dueDate: dateString,
    statementDate: dateString,
    individualProviderName: "",
    itemizedCharges: [],
    insuranceAlterations: [],
    paidByUser: 0,
    payments: [],
    providerId: "",
    recipient: "self",
  };
};

function CreateBillForm({
  user,
  billImagesId,
  userRefetch,
  setUpdateBill,
}: {
  user: UserFragment;
  billImagesId: string | null;
  userRefetch: () => void;
  setUpdateBill: (billId: string) => void;
}) {
  const [formData, setFormData] = useState<any>(null);
  const [billIdCreated, setBillIdCreated] = useState<string | null>(null);
  const dateString = moment().format("YYYY-MM-DD");
  const [createBillMutation, createBillResult] = useCreateBillMutation();
  const [updateBillImagesMutation, updateBillImagesResult] =
    useUpdateBillImagesMutation();

  const onSave = async (billInput: any) => {
    const result = await createBillMutation({
      variables: {
        bill: { ...billInput, billImageIds: [billImagesId] },
        userId: user.id,
      },
    });

    if (result.data?.createBill) {
      await updateBillImagesMutation({
        variables: {
          billImagesId: billImagesId as string,
          userId: user.id,
          billImagesInput: {
            billId: result.data.createBill,
          },
        },
      });
      setBillIdCreated(result.data?.createBill);
      setFormData(DEFAULT_BILL(dateString));
    } else {
      console.log(
        "Problem saving bill " +
          createBillResult.error?.message +
          updateBillImagesResult.error?.message
      );
    }
  };

  useEffect(() => {
    setFormData(DEFAULT_BILL(dateString));
  }, [dateString]);

  return (
    <div className="create-bill-form">
      <h1>Create Bill for Image Submission</h1>
      {formData && (
        <BillForm
          setUpdateBill={setUpdateBill}
          isBillUpdate={false}
          user={user}
          userRefetch={userRefetch}
          formData={formData}
          billImagesId={billImagesId}
          onSave={onSave}
          loading={updateBillImagesResult.loading || createBillResult.loading}
          error={
            updateBillImagesResult.error?.message ||
            createBillResult.error?.message
          }
        />
      )}
      {billIdCreated && <div>Created Bill {billIdCreated}</div>}
      <div id="create-provider-modal"></div>
    </div>
  );
}

export default CreateBillForm;
