import { Heading } from "@aws-amplify/ui-react";

function Home({ user }: any) {
  return (
    <div>
      <Heading level={1}>Hello {user.attributes.email}</Heading>
    </div>
  );
}

export default Home;
