import {
  UserFragment,
  useGetBillQuery,
  useGetUserQuery,
  useUpdateBillMutation,
  InsuranceAlteration,
} from "../../generated/graphql";
import BillForm from "./BillForm";
import { useState } from "react";
import { Checkbox } from "@mui/material";

function UpdateBillForm({
  billId,
  userId,
  newBillImageId,
  onBillUpdate,
}: {
  billId: string;
  userId: string;
  newBillImageId?: string;
  onBillUpdate?: () => void;
}) {
  const [addBillImageToBill, setAddBillImageToBill] = useState(true);
  const [updateBillMutation, updateBillResult] = useUpdateBillMutation();

  const billResponse = useGetBillQuery({
    variables: {
      billId,
      userId,
    },
  });

  const convertBillingCodes = (insuranceAlterations: InsuranceAlteration[]) => {
    return insuranceAlterations.map((alteration) => {
      return {
        ...alteration,
        billingCodes: alteration.billingCodes.map((code: string) => {
          return { value: code, id: code };
        }),
      };
    });
  };

  const userResponse = useGetUserQuery({
    variables: {
      userId: userId,
    },
  });

  const bill = {
    ...billResponse.data?.getBill,
    recipient: billResponse.data?.getBill?.recipient || "self",
    insuranceAlterations: billResponse.data?.getBill?.insuranceAlterations
      ? convertBillingCodes(billResponse.data?.getBill.insuranceAlterations)
      : [],
  };
  const user = userResponse.data?.getUser as UserFragment;

  const onSave = async (billInput: any) => {
    const { userId, id, provider, ...rest } = billInput;
    await updateBillMutation({
      variables: {
        billId,
        updatedBill: {
          ...rest,
          recipient: rest.recipient === "self" ? null : rest.recipient,
          billImageIds: newBillImageId
            ? [...(rest.billImageIds || []), newBillImageId]
            : rest.billImageIds,
          amountOwed: rest.amountOwed || 0,
        },
        userId: user.id,
      },
    });
    if (onBillUpdate) onBillUpdate();
  };

  return (
    <>
      {user && bill && (
        <BillForm
          userRefetch={userResponse.refetch}
          isBillUpdate={true}
          user={user}
          billImagesId={"test"}
          formData={bill}
          onSave={onSave}
          loading={updateBillResult.loading}
          error={updateBillResult.error?.message}
        />
      )}
      {newBillImageId && (
        <>
          Add Image To Bill on Save?
          <Checkbox
            checked={addBillImageToBill}
            onChange={() => setAddBillImageToBill(!addBillImageToBill)}
          />
        </>
      )}
      {updateBillResult.data && !updateBillResult.error && (
        <div>Updated successfully</div>
      )}
      <div id="create-provider-modal"></div>
    </>
  );
}

export default UpdateBillForm;
