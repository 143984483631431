import { TextField, Button } from "@mui/material";
import {
  Provider,
  useGetProvidersByZipCodeLazyQuery,
} from "../../generated/graphql";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import ProviderCard from "../cards/ProviderCard";
import UpdateProviderForm from "./UpdateProviderForm";

export type ProviderTabState = {
  selectedProvider: Provider | null;
  listOfProviders: Provider[];
  zipcode: string;
  providerName: string;
  providerId?: string | null;
};

function ProviderInput({
  setProviderState,
  tabState,
}: {
  setProviderState: Function;
  tabState: ProviderTabState;
}) {
  const { zipcode, providerName, selectedProvider, listOfProviders } = tabState;
  const [getProviders, { loading, error }] = useGetProvidersByZipCodeLazyQuery({
    variables: { zipCode: tabState.zipcode },
  });

  const [showCreateModal, setShowCreateModal] = useState(false);

  const { register, setValue } = useFormContext();

  const searchForProvider = async () => {
    if (!zipcode) {
      return;
    }
    const response = await getProviders();
    setProviderState({
      ...tabState,
      listOfProviders: response.data?.getProvidersByZipCode.providers || [],
    });
  };

  const SelectProvider = (provider: Provider | null) => {
    setProviderState({ ...tabState, selectedProvider: provider });
    setValue("providerId", provider && provider.id ? provider.id : null);
  };

  const CancelProvider = () => {
    setProviderState({ ...tabState, selectedProvider: null });
    setValue("providerId", null);
  };

  return (
    <div>
      <h5>Provider</h5>
      <TextField
        size="small"
        label="Individual Provider Name"
        {...register("individualProviderName")}
      />
      {selectedProvider && (
        <div>
          <ProviderCard provider={selectedProvider} />
          <Button onClick={CancelProvider} variant="contained">
            Clear
          </Button>
        </div>
      )}
      {!selectedProvider && (
        <div className="provider-form">
          <div className="provider-search input-list-row-item">
            <div>Search For Provider</div>
            <TextField
              size="small"
              label="ZipCode"
              value={zipcode}
              type="number"
              onChange={(event) =>
                setProviderState({ ...tabState, zipcode: event.target.value })
              }
            />
            <TextField
              size="small"
              label="Provider Name"
              value={providerName}
              onChange={(event) =>
                setProviderState({
                  ...tabState,
                  providerName: event.target.value,
                })
              }
            />
            <Button
              disabled={loading}
              onClick={searchForProvider}
              variant="contained"
            >
              Search
            </Button>
          </div>
          {listOfProviders && (
            <div className="existing-providers">
              <div>Existing Providers</div>
              {loading && <div>Searching...</div>}
              {error && <div>Error: {error.message}</div>}
              {listOfProviders.map((provider: any) => {
                return (
                  <ProviderCard
                    provider={provider}
                    onSelect={() => SelectProvider(provider)}
                    key={provider.id}
                  />
                );
              })}
            </div>
          )}
          {!showCreateModal && (
            <Button
              type="button"
              size="small"
              variant="contained"
              onClick={() => setShowCreateModal(true)}
            >
              Create New Provider
            </Button>
          )}
          {showCreateModal && (
            <UpdateProviderForm
              AfterSubmit={(provider: any) => {
                setShowCreateModal(false);
                if (provider && provider.id) {
                  SelectProvider(provider);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ProviderInput;
