import {
  BillImagesFragment,
  UserFragment,
  useUpdateBillImagesMutation,
} from "../generated/graphql";
import CreateBillForm from "./form/CreateBillForm";
import UpdateBillForm from "./form/UpdateBillForm";
import { List, Paper, ListItem, Button, Checkbox, Alert } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useCallback, useState } from "react";
import { CheckBox as CheckboxIcon } from "@mui/icons-material";
import "./BillImageEntryDisplay.css";

const BillImagesComponent = ({
  billImages,
  display,
}: {
  billImages: BillImagesFragment;
  display: boolean;
}) => {
  const [isEOB, setIsEOB] = useState<boolean>(billImages.isEOB ?? false);
  const [updateBillImagesMutation, { loading, error }] =
    useUpdateBillImagesMutation();

  const processBillImages = useCallback(async () => {
    await updateBillImagesMutation({
      variables: {
        userId: billImages.userId,
        billImagesId: billImages.id,
        billImagesInput: {
          isEOB,
          isProcessed: true,
        },
      },
    });
  }, [isEOB, billImages, updateBillImagesMutation]);

  return (
    <Paper
      className="scrollable-image-display"
      style={{
        display: display ? "flex" : "none",
      }}
      key={billImages.id}
    >
      <List>
        <ListItem key="title">
          <h4>Images for {billImages.id}</h4>
        </ListItem>
        {billImages &&
          billImages.imageUrls &&
          billImages.imageUrls.map((imageUrl) => (
            <ListItem key={imageUrl}>
              <Zoom>
                <img
                  alt="bill scan submission"
                  src={imageUrl!}
                  style={{ width: "100%" }}
                />
              </Zoom>
            </ListItem>
          ))}
        <ListItem key="eob-info">
          <Alert severity="info">
            Mark as EOB before setting as processed if the bill is an
            Explanation of Benefits
          </Alert>
        </ListItem>
        <ListItem key="isEOB">
          Is EOB?
          <Checkbox
            onClick={() => setIsEOB(!isEOB)}
            disabled={billImages.isProcessed ?? false}
            value={isEOB}
          />
        </ListItem>
        <ListItem key="info">
          <Alert severity="info">
            Do not mark as processed until all bills have been created for this
            image submission
          </Alert>
        </ListItem>
        <ListItem key="processed">
          {billImages.isProcessed ? (
            <>
              <CheckboxIcon />
              "️Processed"
            </>
          ) : (
            <Button
              variant="contained"
              type="button"
              onClick={processBillImages}
            >
              Set As Processed
            </Button>
          )}
          {loading && <div>Loading...</div>}
          {error && <div>Error: {error.message}</div>}
        </ListItem>
      </List>
    </Paper>
  );
};

function BillImageEntryDisplay({
  billImages,
  user,
  index,
  userRefetch,
}: {
  billImages: BillImagesFragment[];
  user: UserFragment;
  index: number;
  userRefetch: () => void;
}) {
  const [billUpdated, setBillUpdated] = useState<boolean>(false);
  const [updateBillId, setUpdateBillId] = useState<string | null>(null);

  if (!billImages) {
    return <div>Bill Images not found</div>;
  }

  return (
    <div style={{ display: "flex" }} key="bill-image-entry-display">
      {billImages &&
        billImages.length > 0 &&
        billImages.map((billImage: BillImagesFragment, i: number) => (
          <BillImagesComponent
            key={billImage.id}
            display={i === index}
            billImages={billImage}
          />
        ))}
      <div style={{ width: "50%", height: "90vh" }}>
        {updateBillId ? (
          <>
            <h2>Update Bill {updateBillId}</h2>
            <UpdateBillForm
              billId={updateBillId}
              userId={user.id}
              newBillImageId={
                billImages[index] ? billImages[index].id : undefined
              }
              onBillUpdate={() => {
                setBillUpdated(true);
                setUpdateBillId(null);
              }}
            />
          </>
        ) : (
          <CreateBillForm
            setUpdateBill={(billId) => {
              setUpdateBillId(billId);
              setBillUpdated(false);
            }}
            user={user}
            userRefetch={userRefetch}
            billImagesId={billImages[index] ? billImages[index].id : null}
          />
        )}
        {billUpdated && <div>Bill Updated Successfully</div>}
        {updateBillId && (
          <Button
            onClick={() => {
              setUpdateBillId(null);
              setBillUpdated(false);
            }}
          >
            Cancel Bill Edit
          </Button>
        )}
      </div>
    </div>
  );
}

export default BillImageEntryDisplay;
