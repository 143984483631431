import { useGetUsersQuery } from "../generated/graphql";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

function Users() {
  const [nextToken, setNextToken] = useState(null);

  const { data, loading } = useGetUsersQuery({
    variables: {
      paginationOptions: {
        limit: 25,
        nextToken,
      },
    },
  });

  return (
    <div>
      <h1>Users</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="users table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getUsers?.users &&
              data.getUsers.users.map((user) => (
                <TableRow key={user?.id}>
                  <TableCell component="th" scope="row">
                    <Link to={user?.id}>{user?.id} </Link>
                  </TableCell>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>{user?.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Users;
