import { useParams } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  BillImagesFragment,
  useGetBillImagesByUserIdQuery,
  useGetUserQuery,
} from "../generated/graphql";
import BillImageEntryDisplay from "../components/BillImageEntryDisplay";

function UserSpecificBillSubmissions() {
  const [billImages, setBillImages] = useState<BillImagesFragment[]>([]);
  const [index, setIndex] = useState(0);
  const params = useParams();
  const userId = params.userId || "test";

  const { data, loading } = useGetBillImagesByUserIdQuery({
    variables: {
      userId,
      paginationOptions: {
        limit: 10,
      },
    },
  });
  const userResponse = useGetUserQuery({
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (data && data.getBillImagesByUserId?.billImages) {
      setBillImages(
        data.getBillImagesByUserId?.billImages.filter(
          (billImage) => billImage?.pending === false
        )
      );
    }
  }, [data]);

  if (loading || userResponse.loading) {
    return <div>Loading...</div>;
  }

  if (!userResponse.data?.getUser) {
    return <div>User not found</div>;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <h1>Bill Submissions for {userResponse.data?.getUser?.name}</h1>
      <BillImageEntryDisplay
        user={userResponse.data?.getUser}
        userRefetch={userResponse.refetch}
        billImages={billImages}
        index={index}
      />
      <BottomNavigation
        showLabels
        value={index}
        onChange={(_event, newValue) => {
          setIndex(newValue);
        }}
      >
        {index > 0 && (
          <BottomNavigationAction
            label="Previous"
            value={index - 1}
            icon={<NavigateBefore />}
          />
        )}
        {index < billImages.length - 1 && (
          <BottomNavigationAction
            label="Next"
            value={index + 1}
            icon={<NavigateNext />}
          />
        )}
      </BottomNavigation>
    </div>
  );
}

export default UserSpecificBillSubmissions;
