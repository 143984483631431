import { Button, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FamilyMemberFragment, UserFragment } from "../../generated/graphql";
import AddRecipientForm from "./AddRecipientForm";
import ReactHookFormSelect from "./ReactHookFormSelect";

function RecipientInput({
  user,
  userRefetch,
}: {
  user: UserFragment;
  userRefetch: () => void;
}) {
  const [showAdd, setShowAdd] = useState(false);
  const [saved, setSaved] = useState(false);

  const { control, setValue, getValues } = useFormContext();

  return (
    <div>
      <h5>Recipient</h5>
      <div className="spaced-section">
        {!showAdd && (
          <>
            <ReactHookFormSelect
              id="recipient"
              name="recipient"
              className="select"
              label="Bill Recipient"
              control={control}
              defaultValue={getValues("recipient") || "self"}
              variant="outlined"
              margin="normal"
            >
              {[
                { id: "self", firstName: "self", lastName: "" },
                ...user.familyMembers,
              ].map((member) => (
                <MenuItem key={member.id} value={member.id}>
                  {member.firstName} {member.lastName}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
            {saved && <div>New family member added!</div>}
            <Button size="small" onClick={() => setShowAdd(true)}>
              Add Family Member
            </Button>
          </>
        )}

        {showAdd && (
          <AddRecipientForm
            afterSubmit={(familyMember?: FamilyMemberFragment) => {
              if (familyMember) {
                userRefetch();
                setValue("recipient", familyMember.id);
              }
              setShowAdd(false);
              setSaved(true);
            }}
            userId={user.id}
          />
        )}
      </div>
    </div>
  );
}

export default RecipientInput;
