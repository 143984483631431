import config from "./config.json";

const awsexports = (stage = "dev") => {
  const defaults = config.defaults;

  let stage_map = defaults;

  if (Object.hasOwn(config.stages, stage)) {
    stage_map = {
      ...defaults,
      ...config.stages[stage],
    };
  }

  return {
    Auth: {
      region: stage_map.region,
      userPoolId: stage_map.cognito.user_pool_id,
      userPoolWebClientId: stage_map.cognito.user_pool_web_client_id,
    },
    graphqlEndpoint: stage_map.graphql.endpoint,
  };
};

export default awsexports;
