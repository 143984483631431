import { Button, TextField, Box, Tab, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { BillFragment, UserFragment } from "../../generated/graphql";
import ItemizedChargesInput from "./ItemizedChargesInput";
import InsuranceAlterationsInput from "./InsuranceAlterationsInput";
import BillIdentification from "./BillIdentificationInput";
import PaymentsInput from "./PaymentsInput";
import RecipientInput from "./RecipientInput";
import BillList from "../BillList";
import ProviderInput, { ProviderTabState } from "./ProviderInput";
import "./BillForm.css";

function BillForm({
  user,
  formData,
  onSave,
  error,
  loading,
  userRefetch,
  isBillUpdate,
  setUpdateBill,
}: {
  user: UserFragment;
  billImagesId: string | null;
  formData: any;
  onSave: (billInput: any) => Promise<void>;
  error?: string | null;
  loading?: boolean;
  userRefetch: () => void;
  isBillUpdate: boolean;
  setUpdateBill?: (billId: string) => void;
}) {
  const [billingCodes, setBillingCodes] = useState<string[]>([]);
  const [amountOwed, setAmountOwed] = useState<number>(0);
  const [amountAfterIns, setAmountAfterIns] = useState<number>(0);
  const [tab, setTab] = useState("1");

  const methods = useForm<BillFragment>({
    defaultValues: formData,
  });
  const [providerTabState, setProviderTabState] = useState<ProviderTabState>({
    zipcode: "",
    providerName: "",
    selectedProvider: formData.provider,
    providerId:
      formData.providerId || (formData.provider ? formData.provider.id : ""),
    listOfProviders: [],
  });

  const { register, handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    reset(formData);
    setProviderTabState({
      zipcode: "",
      providerName: "",
      selectedProvider: formData.provider,
      providerId:
        formData.providerId || (formData.provider ? formData.provider.id : ""),
      listOfProviders: [],
    });
  }, [formData, reset]);

  const onSubmit = async (formData: any) => {
    try {
      const totalAmountDue =
        formData.itemizedCharges && formData.itemizedCharges.length > 0
          ? formData.itemizedCharges.reduce(
              (acc: number, curr: any) => acc + Number(curr.billedAmount),
              0
            )
          : Number(formData.amountOwed);
      const paidByUser =
        formData.payments && formData.payments.length > 0
          ? formData.payments.reduce(
              (acc: number, curr: any) => acc + Number(curr.amount),
              0
            )
          : Number(formData.paidByUser);
      const paidByInsurance =
        formData.insuranceAlterations &&
        formData.insuranceAlterations.length > 0
          ? formData.insuranceAlterations.reduce(
              (acc: number, curr: any) => acc + Number(curr.amount),
              0
            )
          : 0;

      const amountDue: number = totalAmountDue - paidByInsurance;
      const insuranceAlterations = formData.insuranceAlterations.map(
        (alteration: any) => {
          return {
            ...alteration,
            billingCodes: alteration.billingCodes.map(
              (val: { value: string }) => val.value
            ),
          };
        }
      );

      const billInput = {
        ...formData,
        recipient:
          formData.recipient && formData.recipient === "self"
            ? null
            : formData.recipient,
        paidByUser: paidByUser || 0,
        amountDue,
        amountOwed: amountDue - paidByUser,
        paidInFull: paidByUser >= amountDue,
        invoiceNumber: formData.invoiceNumber || "NA",
        accountId: formData.accountId || "NA",
        insuranceAlterations,
      };

      await onSave(billInput);
      setTab("1");
    } catch (error) {
      // Handle any errors from the mutation
      console.error(error);
    }
  };

  const setTotamAmountOwed = (amount: number) => {
    setAmountAfterIns(amount);
    setValue("amountOwed", amount);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={(event, index) => setTab(index)}>
          <Tab label="General" value="1" />
          <Tab label="Provider" value="2" />
          <Tab label="Itemized Charges / Payments" value="3" />
          {!isBillUpdate && <Tab label="Existing Bills" value="4" />}
          {formData.billImageIds && formData.billImageIds.length > 0 && (
            <Tab label="Bill Images" value="5" />
          )}
        </TabList>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TabPanel value="1">
            <Container className="general-input">
              <BillIdentification
                setUpdateBill={setUpdateBill}
                isBillUpdate={isBillUpdate}
                user={user}
              />
              <RecipientInput user={user} userRefetch={userRefetch} />
              <h5>Dates</h5>
              <TextField
                size="small"
                label="Service Date"
                {...register("serviceDate")}
              />
              <TextField
                size="small"
                label="Statement Date"
                {...register("statementDate")}
              />
              <TextField
                size="small"
                label="Due Date"
                {...register("dueDate")}
              />
            </Container>
          </TabPanel>
          <TabPanel value="2">
            <ProviderInput
              setProviderState={setProviderTabState}
              tabState={providerTabState}
            />
          </TabPanel>
          <TabPanel value="3">
            <ItemizedChargesInput
              setAmountOwed={setAmountOwed}
              setBillingCodes={setBillingCodes}
            />
            <InsuranceAlterationsInput
              billingCodes={billingCodes}
              amountOwedBeforeAlts={amountOwed}
              sendAmountAfterIns={setTotamAmountOwed}
            />
            <TextField
              size="small"
              label="Total Amount Owed"
              {...register("amountOwed")}
            />
            <PaymentsInput amountAfterIns={amountAfterIns} />
          </TabPanel>
          <TabPanel value="4">
            <BillList />
          </TabPanel>
          <TabPanel value="5"></TabPanel>
          {error && <div>Error: {error}</div>}
          {loading && <div>Loading...</div>}
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </FormProvider>
    </TabContext>
  );
}

export default BillForm;
