import { Provider } from "../../generated/graphql";
import { Button, Container } from "@mui/material";
import "./ProviderCard.css";

function ProviderCard({
  provider,
  onSelect,
  onEdit,
}: {
  provider: Provider;
  onSelect?: Function;
  onEdit?: Function;
}) {
  return (
    <Container className="provider-card">
      <div>Name: {provider.name}</div>
      <div>Phone: {provider.phoneNumber}</div>
      <div>Website: {provider.billingWebAddress}</div>
      <div>Availability: {provider.availability}</div>
      <div>
        Address:
        {provider.address.street},{" "}
        {provider.address.streetAdditional
          ? provider.address.streetAdditional + ", "
          : ""}
        {provider.address.city}, {provider.address.state}{" "}
        {provider.address.zipCode}
      </div>
      {onSelect && (
        <Button
          size="small"
          variant="contained"
          onClick={() => onSelect(provider.id)}
        >
          Select
        </Button>
      )}
      {onEdit && (
        <Button
          size="small"
          variant="contained"
          onClick={() => onEdit(provider.id)}
        >
          Edit
        </Button>
      )}
    </Container>
  );
}

export default ProviderCard;
