import { createPortal } from "react-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState, useEffect } from "react";
import { Button, Container, TextField } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { ProviderInput } from "../../generated/graphql";
import { useCreateProviderMutation } from "../../generated/graphql";
import AddressForm from "./AddressForm";

const validationSchema = z.object({
  address: z.object({
    street: z.string().min(1, { message: "Street is required" }),
    streetAdditional: z.string(),
    city: z.string().min(1, { message: "City is required" }),
    state: z.string().min(1, { message: "State is required" }),
    zipCode: z.string().min(5, { message: "Zip code is required" }),
  }),
  availability: z.string().min(1, { message: "Availability is required" }),
  billingWebAddress: z.string().url(),
  name: z.string().min(1, { message: "Name is required" }),
  phoneNumber: z.string().min(1, { message: "Phone number is required" }),
});

function UpdateProviderForm({ AfterSubmit }: { AfterSubmit: Function }) {
  const methods = useForm<ProviderInput>({
    defaultValues: {
      address: {
        street: "",
        streetAdditional: "",
        zipCode: "",
        city: "",
        state: "",
      },
      availability: "",
      billingWebAddress: "",
      name: "",
      phoneNumber: "",
    },
    resolver: zodResolver(validationSchema),
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [createProvider, { loading, error }] = useCreateProviderMutation();
  const onSubmit = async (formData: ProviderInput) => {
    try {
      const response = await createProvider({
        variables: {
          provider: formData,
        },
      });

      if (!error && !loading) {
        reset();
        AfterSubmit(response.data?.createProvider);
      }
    } catch (e) {}
  };

  return (
    <ModalPortal>
      <Container>
        <FormProvider {...methods}>
          <form>
            <h4>Create Provider</h4>
            <TextField
              size="small"
              label="Name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register("name")}
            />
            <TextField
              size="small"
              label="Availability"
              error={!!errors.availability}
              helperText={errors?.availability?.message}
              {...register("availability")}
            />
            <TextField
              size="small"
              label="Billing Web Address"
              error={!!errors.billingWebAddress}
              helperText={errors?.billingWebAddress?.message}
              {...register("billingWebAddress")}
            />
            <TextField
              size="small"
              label="Phone Number"
              error={!!errors.phoneNumber}
              {...register("phoneNumber")}
            />
            <AddressForm />
            {error && <div>Error: {error.message}</div>}
            <div className="spaced-section">
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                variant="contained"
              >
                Save
              </Button>
              <Button onClick={() => AfterSubmit()}>Cancel</Button>
            </div>
          </form>
        </FormProvider>
      </Container>
    </ModalPortal>
  );
}

const ModalPortal = (props: any) => {
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return domReady
    ? createPortal(
        props.children,
        document.getElementById("create-provider-modal")!
      )
    : null;
};

export default UpdateProviderForm;
