import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

function BillingCodeInput({ index, optionsList }: any) {
  const [billingCodeOptions, setBillingCodeOptions] = useState<string[]>([]);

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `insuranceAlterations.${index}.billingCodes`,
  });

  useEffect(() => {
    const codes = new Set(fields.map((field: any) => field.value));
    setBillingCodeOptions(
      [...optionsList].filter((option: any) => !codes.has(option))
    );
  }, [setBillingCodeOptions, fields, optionsList]);

  return (
    <div className="list-field-input">
      Billing Codes
      <div className="field-values">
        {fields.map((field: any) => (
          <Chip
            key={field.id}
            color="success"
            label={field.value}
            onDelete={() => remove(field.id)}
          />
        ))}
      </div>
      <div className="field-options">
        {billingCodeOptions.map((option: string) => (
          <Chip
            key={option}
            label={option}
            onClick={() => append({ value: option })}
          />
        ))}
      </div>
    </div>
  );
}

export default BillingCodeInput;
