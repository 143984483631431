import * as React from "react";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const removeTypenameLink = removeTypenameFromVariables();

const config = awsExports(process.env.REACT_APP_STAGE);

Amplify.configure({
  Auth: config.Auth,
  Analytics: {
    disabled: true,
  },
});

function App({ children }: any) {
  const httpLink: ApolloLink = new HttpLink({
    uri: config.graphqlEndpoint,
  });

  const cache = new InMemoryCache();

  const authMiddleware = setContext(async (operation) => {
    const token = await Auth.currentSession().then((session) =>
      session.getIdToken().getJwtToken()
    );
    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  });

  const client = new ApolloClient({
    link: from([removeTypenameLink, authMiddleware.concat(httpLink)]),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default withAuthenticator(App);
